@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.audio {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.duck {
  position: fixed;
  width: rem(568);
  height: rem(702);

  pointer-events: none;
  user-select: none;

  @media (max-width: $tablet) {
    width: rem(200);
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: bottom;
  }
}
