@font-face {
  font-family: Knewave;
  font-display: swap;
  src: url('../assets/fonts/Knewave-Regular.woff2') format('woff2'),
    url('../assets/fonts/Knewave-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: KiwiMaru;
  font-display: swap;
  src: url('../assets/fonts/KiwiMaru-Medium.woff2') format('woff2'),
    url('../assets/fonts/KiwiMaru-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: KiwiMaru;
  font-display: swap;
  src: url('../assets/fonts/KiwiMaru-Regular.woff2') format('woff2'),
    url('../assets/fonts/KiwiMaru-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
