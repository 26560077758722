@use 'sass:math';

// Подключения миксинов ===========================================================================================================================================================================================================================================================================
@import './mixins.scss';

// Подключения шрифтов ===========================================================================================================================================================================================================================================================================
//&display=swap - додати при підключенні через плагін

// Подключить, если есть локальные файлы шрифтов
@import './fonts.scss';

// ============================================================================================================================================================================================================================================================================================================================================================================================
// Настройка шаблону ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Шрифт по умолчанию ==============================================================================================================================================================================================================================================================================
$fontFamily: 'Knewave', sans-serif, -apple-system, BlinkMacSystemFont, Roboto,
  Ubuntu, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji';
$fontSize: rem(14);

// Основной цвет
$mainColor: cl(primary);

// Цвета
@import './colors-palette';

// Сетка  ===============================================================================================================================================================================================================================================================================================

// Минимальная ширина страницы
$minWidth: 360;
// Ширина полотна (макета)
$maxWidth: 1440;
// Ширина ограничивающего контейнера (0 = нет ограничения)
$maxWidthContainer: 980;
// Загальний відступ у контейнера
// (30 = по 15px ліворуч і праворуч, 0 = немає відступу)
$containerPadding: 30;

// Ширина спрацьовування першого брейкпоінту
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поїнти
// $pc: em($containerWidth); // ПК, ноутбуки
$pc: em(1440); // ПК, ноутбуки
$tablet: em(991.98); // Планшети
$mobile: em(767.98); // Телефоны L
$mobileSmall: em(479.98); // Телефоны S
$time: 0.3s; // Тайминг на анимацию transition
$tr: ease-out; // Тип на анимации на transition
// ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Обнуленние ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
@import './null';

// Стили тега BODY ============================================================================================================================================================================================================================================================================================================================================================================================
body {
  background-color: #fff;

  .lock & {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }

  &._lock {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }

  .loaded & {
  }
  // &::-webkit-scrollbar {
  //   height: rem(5);
  //   width: rem(6);
  // }
  // &::-webkit-scrollbar-track {
  //   background: cl(scroll-bg);
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: rem(10);
  //   background: cl(alt);
  // }
}

#root {
  min-height: 100%;
}

// Оболочка wrapper ============================================================================================================================================================================================================================================================================================================================================================================================================================================
.wrapper {
  // min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  // background-color: red;

  @supports (overflow: clip) {
    overflow: clip;
  }
  // Прижимаем
  > main {
    flex: 1 1 auto;
  }
  // Фикс для слайдеров
  > * {
    min-width: 0;
  }
}
.page {
  flex: 1 1 auto;
  /* margin: rem(32) 0 0;

  @media (max-width: $mobile) {
    margin: rem(16) 0 0;
  } */
}
::selection {
  // color: cl(primary);
  // background: cl(bg);
}
// ======================================================================================================================================================================================================================

[class*='__container'] {
  max-width: calc(rem($maxWidthContainer) + rem(20));
  width: 100%;
  box-sizing: border-box;
  padding: 0 rem(15);
  margin: 0 auto;
}

* {
  // transition: none !important;
}
