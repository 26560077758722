@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.main {
  padding: rem(55) 0;
  min-height: 100vh;
  background-image: url(./assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: $tablet) {
    padding: rem(150) 0;
  }

  &Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(40);

    &__title {
      width: rem(370);
      height: rem(134);

      @media (max-width: $mobile) {
        width: rem(280);
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    &__avatar {
      width: rem(290);
      height: rem(290);
      border-radius: rem(18);
      border: rem(5.4) solid #fff;
      overflow: hidden;

      @media (max-width: $tablet) {
        width: rem(260);
        height: rem(260);
        border: rem(4) solid #fff;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__text {
      font-family: 'Kiwi Maru', sans-serif;
      font-weight: 500;
      font-size: rem(31);
      line-height: normal;
      text-align: center;
      color: #000;

      @media (max-width: $tablet) {
        font-size: rem(26);

        br {
          display: none;
        }
      }
    }
    &__address {
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: rem(10);

      border: rem(3) solid #ffa800;
      border-radius: rem(47);
      padding: rem(14) rem(28);
      background-color: #000;

      @media (max-width: $tablet) {
        border-radius: rem(30);
      }

      span {
        max-width: calc(100% - rem(48));
        display: block;
        font-family: 'Kiwi Maru', sans-serif;
        font-weight: 400;
        font-size: rem(30);
        line-height: normal;
        color: #fff;
        word-wrap: break-word;

        @media (max-width: $tablet) {
          font-size: rem(24);
        }
      }
      button {
        flex: 0 0 auto;
        width: rem(38);
        height: rem(38);

        transition: all $time $tr;

        &:hover {
          opacity: 0.9;
          transform: scale(0.95);
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__links {
      display: flex;
      justify-content: center;
      gap: rem(60);

      a {
        display: flex;
        align-items: center;
        gap: rem(8);
        padding: rem(16) rem(30);

        border-radius: rem(34);
        border: rem(6) solid #fff;
        box-shadow: 0 rem(4) rem(16) 0 rgba(0, 0, 0, 0.25);
        background: linear-gradient(180deg, #ffe145 0%, #bf5c24 100%);

        font-weight: 400;
        font-size: rem(33);
        text-align: center;
        color: #000;

        transition: all $time $tr;

        &:hover {
          opacity: 0.9;
          transform: scale(0.95);
        }

        svg {
          width: rem(42);
          height: rem(42);
        }
      }
    }
  }
}
